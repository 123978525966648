// const { JSDOM } = require( "jsdom" );
// const { window } = new JSDOM( "" );
// const $ = require( "jquery" )( window );
// import Calendar from "color-calendar";


$(document).ready(function () {

  $('.media-tab').click(function () {
    $('.media-tab').toggleClass('media-tab_active');
    $('.description-content__part').toggleClass('description-content__part_show');
    $('.gallery-block').toggleClass('gallery-block_show');
  });

  $('.slider-list').slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,

    prevArrow: $('.prev-arr'),
    nextArrow: $('.forward-arr'),
    responsive: [
      {
        breakpoint: 980,
        settings: "unslick"
      }
    ]
  });

  $('.burger-btn').click(function () {
    $('.burger-btn').toggleClass('burger-btn_clicked');
    $('.header-top-menu').toggleClass('header-top-menu_active');
  });

  $(window).scroll(function () {
    let windowHeight = $(window).scrollTop();
    if (windowHeight >= 250) {
      $('.go-to-top').css('display', 'flex');
    } else if (windowHeight < 250) {
      $('.go-to-top').css('display', 'none');
    }
  });
  $('.go-to-top').click(function () {
    $("html, body").animate({scrollTop: 0}, "slow");

    // return false;
  })
});

if ($('.content-item__gallery a').length) {
  let gallery = new SimpleLightbox('.content-item__gallery a', {});
}

let calendar = new Calendar({
  id: "#color-calendar",
  calendarSize: "large",
  headerColor: 'rgb(129, 197, 98)',
  headerBackgroundColor: 'rgb(129, 197, 98)',
  customWeekdayValues: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', ],
  startWeekday: 1,
  customMonthValues: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень',
    'Вересень', 'Жовтень', 'Листопад', 'Грудень',],
  monthChanged: (currentDate, DateEvents) => {
    setTimeout(() => {
      calendar.eventsData.forEach(el => {
        let d = new Date(el.start);
        let n = d.getUTCDate();
        let date = $(".calendar__day-text:contains(" + n + ")");
        date.append('<a class="calendar-tooltip" title="' + el.title + '" href="' + el.url + '"></a>')
        // let tooltips = date.find('.calendar-tooltip')
        // if (tooltips.length) {
        //   // console.log(tooltips)
        //   tooltips.each((i, val) => {
        //     val.remove()
        //   })
        // }
        // let tooltip = $('.calendar-tooltip')
        // let tooltipClone = tooltip.clone()
        // tooltipClone.find('a').attr('href', el.url)
        // tooltipClone.find('.calendar-tooltip__title').html(el.title)
        // tooltipClone.find('.calendar-tooltip__img').attr('src', el.image)
        // date.append(tooltipClone)
      })
    }, 200)
  },
});
